import { motion, useAnimationControls, useInView } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TranslateText from '../../utils/translateText';
import { TitleGray, TitleLeft, TitleSmall } from '../styledComponents';
import RowItem from './components/rowItem';
import { OuterDiv } from '../styledComponents';
import { db } from '../../utils/firebase';
import { doc, onSnapshot } from "firebase/firestore";

const TextWrap = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items:flex-start;
  width: 100%;
  height: fit-content;
  overflow: visible;
`;

const RowWrap = styled(motion.div)`
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  height: fit-content;
  overflow: visible;
  margin-top: 2rem;

  @media screen and (max-width:950px) {
    flex-direction: column;
    justify-content:flex-start;
    margin-bottom: 3rem;
    margin-top: max(3vh, 1.3rem);
  }
`;

const rowItemData = [
  {
    textSwe: 'Kundnöjdhet',
    textEng: 'customer satisfaction rating',
    prefix: '%',
    dataKey: 'customer',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
  },
  {
    textSwe: `Plockrundor optimerade`,
    textEng: 'Picking routes optimized',
    prefix: '+',
    dataKey: 'algoUsage',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0.2 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
  },
  {
    textSwe: 'Minskat plockavstånd',
    textEng: 'Picking distance saved',
    prefix: 'km+',
    dataKey: 'distance',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0.4 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
  },
  /*{
    textSwe: 'Prestationsrapporter',
    textEng: 'Performance reports',
    prefix: '+',
    dataKey: 'reports',
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.3, delay: 0.4 } },
    hidden: { opacity: 0, scale: 1, y: 100 },
  }*/
]

function DataSection() {
  const [statsData, setStatsData] = useState([]);
  const ref = useRef(null)
  const rowRef = useRef(null)
  const inView = useInView(ref, { once: true, margin: "0px 0px 200px 0px" })
  const rowInView = useInView(rowRef, { once: true, margin: "0px 0px 100px 0px" })
  const rowAnimationControls = useAnimationControls()
  const animationControls = useAnimationControls()

  useEffect(() => {
    if (typeof window !== "undefined") {
      let width = window.innerWidth;
      if (inView || width < 600) {
        animationControls.start("visible");
      }

      if (rowInView || width < 600) {
        rowAnimationControls.start("visible");
      }
    }

  }, [inView, rowInView]);

  //Listen for changes in the tasks
  useEffect(() => {
    const collectionRef = doc(db, "landingpage/data");

    const unsub = onSnapshot(collectionRef, async (snapShot) => {
      //Set the tasks
      const newObject = {
        ...snapShot.data(),
        distance: Number((snapShot.data().distance/1000).toFixed(0))
      }
      setStatsData(newObject)
    });
    return () => unsub;

  }, [])

  const variants = {
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: 0.2, delay: 0 } },
    hidden: { opacity: 0.2, scale: 0.9, y: 100 },
  }

  return (
    <OuterDiv
      initial={"hidden"}
      animate={animationControls}
      variants={variants}
      viewport={{ once: false }}
      ref={ref}
      minheight={'800px'}
    >
      <TitleSmall leftAligned>
        <TranslateText swedish={'Detta är Paretro'} english={'This is Paretro'} />
      </TitleSmall>
      <TextWrap>
        <TitleLeft >
          <TranslateText swedish={'Optimering '} english={'Optimizing of'} />
          <TitleGray>
            <TranslateText
              swedish={' av världsledande verksamheter, varje dag, hela tiden.'}
              english={' world leading companies every day ,all the time'} />
          </TitleGray>
        </TitleLeft>
      </TextWrap>
      <RowWrap ref={rowRef} >
        {rowItemData.map((item, index) => {
          return (
            <RowItem item={item} key={item.textSwe} animationControls={rowAnimationControls} inView={inView} data={statsData} index={index}/>
          )
        })}
      </RowWrap>
    </OuterDiv>
  )
}

export default DataSection;