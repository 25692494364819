import { animate } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const NumberText = styled.span`
  font-size: 7.5rem;
  letter-spacing: -.02em;
  line-height: 90%;
  align-self: flex-start;
  font-weight: 200;

  @media (max-width: 1200px)  {
    font-size: calc((170 / 1000)*100*1vw);
  }

  @media (max-width: 800px)  {
    font-size: calc((170 / 740)*100*1vw);
  }
`;

const UnitText = styled.span`
    font-size: 2.5rem;
    letter-spacing: -.02em;
    align-self: flex-end;
`;

const TextDiv = styled.span`
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap:5px;
  font-size: 35px;
  color: #FFFFFF;
  @media (max-width: 550px)  {
    justify-content: flex-start;
  }

`;

export default function TextCounter({ to, unitText, delay, inView }) {
  const [animatedValue, setAnimatedValue] = useState(0);  // Initializing with 0 for first render
  const [initialLoad, setInitialLoad] = useState(true);   // State to track initial load
  const calcWidth = Number(String(to).length) * 1.5 + 5;

  useEffect(() => {
    if (inView) {
      const startAnimation = () => {
        const startValue = initialLoad ? 0 : animatedValue;  // Determine starting point
        const controls = animate(startValue, to, {
          duration: 2,
          onUpdate(value) {
            setAnimatedValue(value.toFixed(0));
          },
          onComplete() {
            if (initialLoad) {
              setInitialLoad(false);  // Mark the initial load as completed after first animation
            }
          }
        });

        return () => controls.stop();
      };
      const timeoutId = setTimeout(startAnimation, delay);
      return () => clearTimeout(timeoutId);
    }
  }, [to, delay, inView, animatedValue, initialLoad]);

  return (
    <TextDiv minWidth={`${calcWidth}px`}>
      <NumberText>{animatedValue}</NumberText>
      <UnitText>{unitText}</UnitText>
    </TextDiv>
  );
}