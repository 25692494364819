import { motion } from 'framer-motion'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import delay from '../../../utils/reuseable/delay'
import TextCounter from './textCounter';
import { BsArrowDownShort } from 'react-icons/bs'
import TranslateText from '../../../utils/translateText'

const OuterContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:fit-content;
    height: fit-content;
    box-sizing: border-box;
    background: #1a1a1a;
    height: 60vh;
    max-height:500px;
    border-radius: 10px;
    width: 30vw;
    max-width: 430px;
    padding: 10px 30px 24px 30px;

    @media screen and (max-width:950px) {
        width: 100%;
        max-width: 90vw;
        margin-top: 2rem;
        max-height: 400px;
    }
`

const UpperDiv = styled(motion.div)`
   display: flex;
   flex-direction: column;
   align-items:flex-start;
   justify-content:flex-start;
   width: 100%;
   height: 60%;
`

const UpperTextDiv = styled(motion.div)`
   display: flex;
   flex-direction: row;
   align-items:center;
   justify-content:space-between;
    width: 100%;
`

const LowerDiv = styled(motion.div)`
   display: flex;
   flex-direction: column;
   align-items:space-between;
   justify-content:flex-end;
   width: 100%;
   height: 40%;
`

const UpperText = styled.h2`
    position: relative;
    font-size: 0.85rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    //color:white;
    color:#8b8b8b;
    text-align: left;
`

const BoxText = styled.h2`
    position: relative;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 1.5px;
    color:#c3cdd3;
    text-align: left;
    width: 95%;
    padding-bottom:0.5rem ;

    &:after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #fefefe;
        opacity: .3;
    }

    @media (max-width: 1200px)  {
       width: 100%;
    }

    @media (max-width: 550px)  {
        font-size: 1rem;
        line-height: 1.3rem;
        text-align: left;
    }
`

const LinkSpan = styled.span`
    color: #f1f1f190;
    font-size: inherit;
    width: 25px;
    height: 1rem;
    display: inline-block;
    transform: rotate(-90deg);
`;

export default function RowItem({ item, animationControls, inView, data, index }) {
    const [height, setHeight] = useState(0)

    async function setMove() {
        const newHeigt = Math.random() * 2
        await delay(500 + Math.random() * 10000)
        setHeight(newHeigt)
        await delay(500 + Math.random() * 10000)
        setHeight(0)
    }

    useEffect(() => {
        setMove()
    }, [height])

    const variants = {
        hidden: item?.hidden,
        visible: item?.visible
    }

    return (
        <OuterContainer
            initial={"hidden"}
            animate={animationControls}
            variants={variants}
            viewport={{ once: false }}
        >
            <UpperDiv>
                <UpperTextDiv>
                    <UpperText>KEY STAT</UpperText>
                    <UpperText>{`00${index + 1}`}</UpperText>
                </UpperTextDiv>
                <Image
                    src="/svgs/circles.svg"
                    alt="Next.js Logo"
                    width={50}
                    height={30}
                    priority
                />
            </UpperDiv>
            <LowerDiv>
                <BoxText>
                    <TranslateText
                        swedish={item?.textSwe}
                        english={item?.textEng}
                    />
                    <LinkSpan><BsArrowDownShort /></LinkSpan>
                </BoxText>
                <TextCounter from={0} to={Number(data[item.dataKey]) ?? 0} unitText={item.prefix} delay={100} inView={inView} />
            </LowerDiv>
        </OuterContainer>
    )
}
