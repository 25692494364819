import Link from 'next/link';
import React from 'react';
import { MdOutlineWaterDrop } from 'react-icons/md';
import styled from 'styled-components';
import { WhiteButton } from '../../utils/reuseable/buttons';
import TranslateText from '../../utils/translateText';
import { blackBackground, greytext } from '../styledComponents';
import { GlowOnHover } from './GlowButton';
import { DetailedText } from '../styledComponents';

const HeroBackdrop = styled.div`
    content: "";
    position: absolute;
    width: 100%;
    height: 130vh;
    top: 0;
    z-index: -1;
    background-image: url('/images/heroBackground.svg');
    background-repeat: no-repeat; 
    background-size: cover;       
    overflow: hidden;

    svg path {
        stroke-dasharray: 2000;
        stroke-dashoffset: 2000;
        animation: dash 5s linear forwards;
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }

    @media screen and (max-width:700px) {
        //padding-top: 20vh;
        height:120vh;
    }
`;

const StyledIcon = styled(MdOutlineWaterDrop)`
  color: ${blackBackground};
  font-size: 30px;
  &:hover{
    color:#f1f1f1;
  }
`;

const HeroOuter = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    height: 100vh;
    padding-top: 20vh;
    background: 
    radial-gradient(ellipse 80% 50% at 50% -30%,rgba(11, 84, 118,0.7),rgb(255,255,255,0));
    overflow: visible;
    z-index: 10;
`;

const HeroDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:55vh;
    width: 100%;

    @media screen and (max-width:700px) {
        //padding-top: 20vh;
        height:50vh;
    }
`;

const RowWrap = styled.div`
    display: flex;
    width: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:30px;
    margin-top: 0px;
    letter-spacing: 0cap;

    @media screen and (max-width:600px) {
    flex-direction: column;
    }
`;

const TitleText = styled.h2`
    color:#f1f1f1;
    font-size: 4.5rem;
    margin: 0;
    padding: 0;
    font-weight: 500;
    text-align: center;
    width: auto;

    @media screen and (max-width:1000px) {
    font-size: 4.5rem;
    }

    @media screen and (max-width:550px) {
        font-size: 2.5rem;
        font-weight: 500;
    }
`;

const MobileBreak = styled.br`
    display: block;
    @media screen and (max-width:400px) {
        display: none;
    }
`;

export default function HeroSection({ scrollToNext }) {
    return (
        <HeroOuter>
            <HeroBackdrop />
            <HeroDiv>
                <TitleText>
                    <TranslateText swedish={'Med Data Skapar vi Industri 4.0'} english={'With Data, we Build Industry 4.0'} />
                </TitleText>
                <DetailedText  textalign={'center'}>
                    <TranslateText
                        swedish={'Optimera era befintliga verksamhetsresurser'}
                        english={'Optimize your existing  operational resources '}
                    />
                   <MobileBreak/>
                    <TranslateText
                        //swedish={' med ett datadrivet beslutsfattande.'}
                        //english={'with a data-driven decision-making.'}
                        swedish={' med Big data, IOT och AI.'}
                        english={'with big data, IOT and AI.'}
                    />
                </DetailedText>
                <RowWrap>
                    <Link href={'/contact'} prefetch={false}>
                        <WhiteButton width={'188px'} height={'48px'} widthMobile={'240px'}>
                            <StyledIcon></StyledIcon>
                            <TranslateText swedish={'Kontakta oss'} english={'Contact us'} />
                        </WhiteButton>
                    </Link>
                    <GlowOnHover onClick={scrollToNext}>
                        <TranslateText swedish={'Läs mer'} english={'Learn more'} />
                    </GlowOnHover>
                </RowWrap>
            </HeroDiv>
        </HeroOuter>
    )
}
// 