import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC6K73Tixj86eRj8oEEmZe6HHZPJ0QgFF4",
    authDomain: "lageralgo.firebaseapp.com",
    projectId: "lageralgo",
    storageBucket: "lageralgo.appspot.com",
    messagingSenderId: "422393014801",
    appId: "1:422393014801:web:fee6e018608d4e9bc01fb0"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
