import styled, { keyframes } from 'styled-components';
import { blackBackground } from '../styledComponents';

const glowing = keyframes`
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
`;

const animateGlow = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// old colors: #7e0fff, #0fffc1, #7e0fff

const textColor = keyframes`
  0% {
    color: #7e0fff;
  }
  50% {
    color: #0fffc1;
  }
  100% {
    color: #7e0fff;
  }
`;

export const BacklightDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 50px;
  text-align: center;
  color: #252B37;
  background-color: ${blackBackground};
  //animation: ${textColor} 25s linear infinite;
  //transition: opacity 1s ease-in-out;
  z-index: 1000;

  &:before {
    position: absolute;
    content: "";
    top: 5px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.7);
    -webkit-filter: blur(2vw);
    -moz-filter: blur(2vw);
    -ms-filter: blur(2vw);
    filter: blur(2vw);
    background: linear-gradient(
      45deg,
      #4287f5,
      #187FA7,
      #003651,
      #014F73,
      #2195C7,
      #002bff,
      #1B88B2,
      #093A53,
      #4287f5
    );
    background-size: 100% 100%;
    animation: ${animateGlow} 35s linear infinite;
  }
`;

/*
  #FF4D59,
  #FF4D59,
  #FF4D59,
  #FF4D59,
  #0b5476,
  #0b5476,
  #187FA7,
  #FF4D59,
  #FF4D59
*/

/* Old colors:       
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
*/

export const GlowOnHover = styled.button`
  width: 188px;
  height:48px;
  border: none;
  outline: none;
  color: #f1f1f1;
  font-size: 1.1rem;
  font-weight: 500;
  background: ${blackBackground};
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 5px;

  &:before {
    content: '';
    background: linear-gradient(
      45deg,
      #4287f5,
      #187FA7,
      #003651,
      #014F73,
      #2195C7,
      #002bff,
      #1B88B2,
      #093A53,
      #4287f5
    );

    position: absolute;
    top: -0.5px;
    left: -0.5px;
    background-size: 800%;
    z-index: -1;
    filter: blur(2px);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    animation: ${glowing} 35s linear infinite;
    transition: opacity 1s ease-in-out;
    border-radius: 5px;
    opacity: 1;
    
  }
  &:active {
    //color: #000;
  }


  &:active:after {
    //background: transparent;
  }

  &:hover:before {
    opacity: 1;
    scale: 1.01;
    color: transparent;
  }

  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${blackBackground};
    left: 0;
    top: 0;
    border-radius: 5px;
  }

  @media screen and (max-width:600px) {
      width: 240px;
      font-size: 1rem;
      height: 44px;
    }

`;
