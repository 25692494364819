import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import HeroSection from './heroSection';
import { useAnimationControls, useInView } from 'framer-motion';
import delay from '../utils/reuseable/delay';
import DataSection from './dataSection';
import dynamic from 'next/dynamic';

const ExampleSection = dynamic(() => import('./examplesSection'), { loading: () => 'Loading...', });
const HowItWorks = dynamic(() => import('./whatIsAlgoFlowSection'), { loading: () => 'Loading...', });
const HowWeDoIt = dynamic(() => import('./quoteSection'), { loading: () => 'Loading...', });

const BottomBorder = styled.div`
  display: block;
  width:100vw;
  border-bottom: 0.5px solid #ffffff50;
  z-index: 5;
`;

const ScrollDiv = styled.div`
  height:1px;
`;

const SpaceDiv = styled.div`
  display: block;
  height: 5rem;
  max-height: 15vh ;
  @media screen and (max-width:1100px) {
      height: 4rem;
      width: 100%;
  }
`;

export default function LandingPage() {
  const ref = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const secondInView = useInView(secondRef)
  const thirdInView = useInView(thirdRef)

  const animationControls = useAnimationControls()
  const exampleAnimationControls = useAnimationControls()

  const handleClick = () => {
    const rect = ref.current?.getBoundingClientRect();
    const yOffset = window.scrollY;
    if (rect) {
      const y = yOffset + rect.top - 30;  // Take into account the current scroll position and 200px offset
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const ScrollSecond = async () => {
    if (!secondInView) {
      animationControls.start("hidden");
      await delay(10)
    }
    
    const rect = secondRef.current?.getBoundingClientRect();
    const yOffset = window.scrollY;
    if (rect) {
      const y = yOffset + rect.top -100;  // Take into account the current scroll position and 100px offset
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      let width = window.innerWidth;
      if (thirdInView || width < 600) {
        animationControls.start("visible");
      }
    }
  }, [thirdInView]);

  return (
    <>
      <HeroSection scrollToNext={handleClick} />
      <ScrollDiv ref={ref} />
      <SpaceDiv/>
      <DataSection />
      <SpaceDiv/>
      <BottomBorder />
      <SpaceDiv/>
      <HowItWorks scrollToNext={ScrollSecond} />
      <SpaceDiv/>
      <ScrollDiv ref={secondRef} />
      <HowWeDoIt animationControls={animationControls} inViewRef={secondInView} />
      <SpaceDiv/>
      <SpaceDiv/>
      <ExampleSection scrollRef={thirdRef} animationControls={exampleAnimationControls} />
      <SpaceDiv/>
      <SpaceDiv/>
    </>
  )
}